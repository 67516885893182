import React from 'react';

import Layout from '@/components/layout/public';
import Hero from '@/components/public/page-hero';
import Callout, { ForParentsCallout } from '@/components/public/callout';
import Pricing from '@/components/public/pricing';
import Faq from '@/components/public/faq';
import SEO from '@/components/seo';

const PornBlockerPage = () => (
	<Layout>
		<SEO
			title="Internet filtering and parental controls with DNS"
			description={`Block unwanted content or monitor internet activity by switching to our DNS servers. Keep yourself and your family safe while browsing the internet.`}
		/>

		<Hero title={'Internet filtering for parents'} style={{
			marginBottom: 50,
		}}/>

		<ForParentsCallout style={{
			marginBottom: 50,
		}}/>

		<Pricing style={{
			marginBottom: 50,
		}}/>

		<Callout style={{
			marginBottom: 50,
		}}/>

		<Faq style={{
			marginBottom: 50,
		}}/>
	</Layout>
);


export default PornBlockerPage;
